@let error = error$ | async ;
@let keyboardShortcut = keyboardShortcut$ | async;

<app-confirm-dialog [visible]="keyboardShortcutsService.activeItem"
                    [header]="'enums.Command.' + keyboardShortcutsService.activeItem?.command | translate"
                    confirmButtonLabel="save"
                    cancelButtonLabel="cancel"
                    (confirm)="keyboardShortcut ? onSave(keyboardShortcut) : null"
                    (radCancel)="assignKeyboardShortcutDialog.onXClose()"
                    (xClose)="keyboardShortcutsService.activeItem = null"
                    #assignKeyboardShortcutDialog>
    <div class="d-flex flex-column gap-2">
        <span>{{ 'enterKeyboardShortcut' | translate }}</span>
        <div class="d-flex gap-2 justify-content-center text-uppercase">
            @for (entry of keyboardShortcut$ | async; track entry) {
                @if ($index) {
                    <span> + </span>
                }

                <p-tag [value]="KEYBOARD_CODE_KEY_MAP[entry]"/>
            }
        </div>

        @if (error) {
            <span class="rad-text-label p-error">{{ 'keysAlreadyAssigned' | translate }}</span>
        }
    </div>
</app-confirm-dialog>